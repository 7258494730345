<template>
  <div class="container">
   
    <div class="d-flex justify-content-center" style="margin-top: 15%;">
      <img src="@/assets/logo 01.png" class="img-fluid mx-auto rounded" />
                
    </div>
  
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
