import axios from "axios";
import authHeader from './auth/auth-header';

let headers = {
    "Content-type": "application/json",
    "Accept": "text/plain",
};

//console.log(authHeader());
//Object.assign(headers, authHeader());

const api = axios.create({
    baseURL: process.env.VUE_APP_BE_BASE_URL + '/api',
    headers: headers
});



export default api;