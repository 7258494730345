import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '@/views/Login.vue';
import store from "@/store";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            authRequired: 'false'
        }
    },
    {
        name: 'projects-day-details',
        path: '/projects/day-details/:key',
        component: () => import('@/components/Projects/customerDayDetails.vue'),
        meta: {
            authRequired: 'false'
        }
    },
    {
        path: "/admin/",
        name: "admin",
        component: () => import("@/views/admin/IndexView"),
        meta: {
            authRequired: 'true',
            role: 'Administrator'
        },
        children: [
            {
                path: 'users',
                name: 'admin_users',
                component: () => import('@/components/Users/UserList.vue'),
            },
            {
                path: 'projects',
                component: () => import('@/components/Projects/ProjectList.vue'),
            },
            {
                name: 'admin-projects-details',
                path: 'projects/details/:key',
                component: () => import('@/components/Projects/adminDetails.vue'),
            },
            {
                name: 'admin-projects-day-details',
                path: 'projects/day-details/:key',
                component: () => import('@/components/Projects/adminDayDetails.vue'),
            }
        ]
    },
    {
        path: "/empolyee/",
        name: "empolyee",
        component: () => import("@/views/empolyee/IndexView"),
        meta: {
            authRequired: 'true',
            role: 'Empolyee'
        },
        children: [
            {
                path: 'projects',
                component: () => import('@/components/Projects/employeeProjectList.vue'),
            },
            {
                name: 'employee-projects-details',
                path: 'projects/details/:key',
                component: () => import('@/components/Projects/employeeDetails.vue'),
            },
            {
                name: 'employee-projects-day-details',
                path: 'projects/day-details/:key',
                component: () => import('@/components/Projects/employeeDayDetails.vue'),
            }
        ]
    },
    {
        path: "/customer/",
        name: "customer",
        component: () => import("@/views/customer/IndexView"),
        meta: {
            authRequired: 'true',
            role: 'Customer'
        },
        children: [
            {
                path: 'projects',
                component: () => import('@/components/Projects/customerProjectList.vue'),
            },
            {
                name: 'customer-projects-details',
                path: 'projects/details/:key',
                component: () => import('@/components/Projects/customerDetails.vue'),
            },
            {
                name: 'customer-projects-day-details',
                path: 'projects/day-details/:key',
                component: () => import('@/components/Projects/customerDayDetails.vue'),
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            authRequired: 'false'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        // lazy-loaded
        component: () => import('@/views/Profile.vue'),
        meta: {
            authRequired: 'true',
            role: 'Administrator'
        }
    },
    {
        path: '/Unauthorized',
        name: 'Unauthorized',
        // lazy-loaded
        component: () => import('@/views/UnauthorizedView.vue'),
        meta: {
            authRequired: 'true'
        }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    // {
    //     path: '/:catchAll(.*)*',
    //     name: "PageNotFound",
    //     component: PageNotFound,
    //   },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    /*
    const publicPages = ['login', 'about', 'home', 'e-card'];
    const isPublicPath = publicPages.includes(to.name);
    const authRequired = !publicPages.includes(to.name);
    const loggedIn = store.getters['auth/isAuthenticated']; // localStorage.getItem('user');
    //const loggedIn = store.state.auth.isAuthenticated; //localStorage.getItem('user');

    console.log(to.name);
    console.log('loggedIn:' + loggedIn);

    if (isPublicPath) {
        next();
        return;
    }

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    }
    else {
        next();
    }
    */

    const loggedIn = store.getters['auth/isAuthenticated'];
    const userRole = store.getters['auth/userRole'];

    if (to.meta.authRequired === 'true' && !loggedIn) {
        next('/login');
    }

    if (to.path === '/Unauthorized') {
        next();
    }
    else if (to.meta.authRequired === 'true' && to.meta.role === userRole) {
        next();
    }
    else if (to.meta.authRequired === 'true' && to.meta.role != userRole) {
        next('/Unauthorized');
    }

    next();


});

export default router
