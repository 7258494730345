import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import http from '@/services/http-common';
import moment from 'moment';


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'


http.interceptors.request.use((config) => {
    let user = JSON.parse(localStorage.getItem('user'));
    console.log('user ...' , user);
    if (user && user.token) {
        config.headers.Authorization = `Bearer ${user.token}`
    }
    else {
        store.dispatch('auth/logout');
        router.push('/login');
        
    }
    return config
})
//use it 

const toastOptions = {
    position: "top-right",
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: "fas fa-rocket",
    rtl: true
};

import Pagination from 'v-pagination-3';


createApp(App).use(store).use(router)
.use(moment)
.use(Toast,toastOptions)
.use(Pagination)
.mount('#app')
