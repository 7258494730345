<template>
    <div class="col-md-12">


        <div class="card card-container">
            <img id="profile-img" src="@/assets/logo 01.png" />
            <!-- <img id="profile-img" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" class="profile-img-card" /> -->
            <form name="form" @submit.prevent="handleLogin" class="needs-validation" novalidate>
                <div class="form-row">
                    <div class="form-group">
                        <label for="username">اسم المستخدم</label>
                        <input v-model="v$.loginForm.username.$model" class="form-control"
                            :class="{ 'is-invalid': v$.loginForm.username.$error }">

                        <template v-for="(error, index) of v$.loginForm.username.$errors" :key="index">
                            <div class="invalid-feedback">{{ error.$message }}</div>
                        </template>

                    </div>

                    <div class="form-group">
                        <label for="password">كلمة المرور</label>
                        <input v-model="v$.loginForm.password.$model" type="password" class="form-control" name="password"
                            :class="{ 'is-invalid': v$.loginForm.password.$error }">

                        <template v-for="(error, index) of v$.loginForm.password.$errors" :key="index">
                            <div class="invalid-feedback">{{ error.$message }}</div>
                        </template>

                    </div>
                    <div class="form-group mt-2">
                        <button class="btn btn-primary btn-block" type="submits" :disabled="loginForm.loading">
                            <span v-show="loginForm.loading" class="spinner-border spinner-border-sm"></span>
                            <span>دخول</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useToast } from "vue-toastification";

export default {
    name: 'Login',
    data() {
        return {
            toast: useToast(),
            v$: useVuelidate(),
            loginForm: {
                username: '',
                password: '',
                loading: false,
            },
        };
    },
    validations() {
        return {
            loginForm: {
                username: {
                    required: helpers.withMessage('الحقل مطلوب', required),
                },
                password: {
                    required: helpers.withMessage('الحقل مطلوب', required),
                },
            },
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    created() {
        if (this.loggedIn) {
            this.$router.push('/profile');
        }
    },
    methods: {
        async handleLogin(event) {
            event.preventDefault();
            const loginFormValidateResult = await this.v$.loginForm.$validate();
            if (!loginFormValidateResult) return;
            this.loginForm.loading = true;

            this.$store.dispatch('auth/login', this.loginForm).then(
                () => {
                    let _user = this.$store.state.auth.user;

                    if (_user.userRole == 'Administrator') {
                        this.$router.push('/admin/');
                    }
                    else if (_user.userRole == 'Empolyee') {
                        this.$router.push('/empolyee/');
                    }
                    else if (_user.userRole == 'Customer') {
                        this.$router.push('/customer/');
                    }
                    else {
                        this.$router.push('/');
                    }

                },
                error => {
                    this.loginForm.loading = false;
                  
                    
                    if (error.response.status == 404) {
                        this.toast.error("اسم المستخدم أو كلمة المرور خاطئة");
                    }
                    else {
                        this.toast.error("حدث خطأ ،، كرماً المحاولة مرة أخرى");
                    }
                }
            );
        }
    }
};
</script>

<style scoped>
label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
</style>